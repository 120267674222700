import React, { useState, useRef, useEffect } from 'react';
import { EpubView } from 'react-reader';
import { Button } from 'react-bootstrap';
import './TestEpubPage.css';

const TestEpubPageWithBook = () => {
  const [location, setLocation] = useState('1');
  const [darkMode, setDarkMode] = useState(false);
  const epubRef = useRef(null);

  const book = {
    title: "A Guide to Health",
    author: "Mahatma Gandhi",
    year: "1921",
    subject: "Health/Philosophy",
    description:
      "'A Guide to Health' by Mahatma Gandhi is a concise manual on physical and mental well-being. Gandhi offers advice on diet, hygiene, exercise, and moral living, emphasizing the importance of simplicity, natural remedies, and the connection between physical health and spiritual well-being.",
    link: "/epubs/a_guide_to_health.epub",
  };

  useEffect(() => {
    document.body.className = darkMode ? 'dark-mode' : '';
    
    const applyDarkModeStyles = () => {
      if (epubRef.current && epubRef.current.rendition) {
        const iframe = epubRef.current.rendition.manager?.views[0]?.iframe;
        if (iframe) {
          const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
          const body = iframeDoc.querySelector('body.x-ebookmaker.x-ebookmaker-3');

          if (body) {
            body.style.backgroundColor = darkMode ? '#000' : '#fff';
            body.style.color = darkMode ? '#fff' : '#000';
          }

          const pgHeaders = iframeDoc.querySelectorAll('.pg-boilerplate, .pgheader');
          pgHeaders.forEach(header => {
            header.style.backgroundColor = darkMode ? '#000' : '#fff';
            header.style.color = darkMode ? '#fff' : '#000';
          });
        }
      }
    };

    const rendition = epubRef.current?.rendition;
    if (rendition) {
      applyDarkModeStyles();
      rendition.on('rendered', applyDarkModeStyles);
    }

  }, [darkMode]);

  const handleLocationChanged = (newLocation) => {
    setLocation(newLocation);
  };

  const handleToggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const handleNext = () => epubRef.current?.nextPage();
  const handlePrev = () => epubRef.current?.prevPage();

  return (
    <div className="test-epub-page">
      <Button onClick={handleToggleDarkMode} className="toggle-dark-mode-btn">
        Toggle Dark Mode
      </Button>
      <div className="epub-container">
        <EpubView
          ref={epubRef}
          url={book.link}
          location={location}
          locationChanged={handleLocationChanged}
        />
      </div>
      <div className="navigation-controls">
        <button onClick={handlePrev} className="nav-button" aria-label="Previous Page">
          Previous
        </button>
        <button onClick={handleNext} className="nav-button" aria-label="Next Page">
          Next
        </button>
      </div>
    </div>
  );
};

export default TestEpubPageWithBook;
