import React, { useState, useEffect } from 'react';
import { Film, Lightbulb, Check, Plus } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';

const FilmList = ({ onSelect }) => {
  const navigate = useNavigate();
  const [readingList, setReadingList] = useState([]);
  const [quotes, setQuotes] = useState({});

  // Hardcoded film data
  const films = [
    {
      "title": "Metropolis",
      "author": "Fritz Lang",
      "year": 1927,
      "subject": "Economics",
      "description": "Metropolis is a 1927 German expressionist science-fiction silent film directed by Fritz Lang and written by Thea von Harbou in collaboration with Lang from von Harbou's 1925 novel of the same name (which was intentionally written as a treatment). It stars Gustav Fröhlich, Alfred Abel, Rudolf Klein-Rogge, and Brigitte Helm. Erich Pommer produced it in the Babelsberg Studio for Universum Film A.G. (UFA). Metropolis is regarded as a pioneering science-fiction film, being among the first feature-length ones of that genre. Filming took place over 17 months in 1925–26 at a cost of more than five million Reichsmarks, or the equivalent of about €21 million.",
      "summary": "John Stuart Mill's 'Principles of Political Economy' is a foundational text in the field of economics, presenting a comprehensive analysis of production, distribution, and consumption of wealth. The work systematically explores various aspects of economic theory and policy, divided into five books.",
      "link": "https://player.vimeo.com/video/1003281166?badge=0&autopause=0&player_id=0&app_id=58479",
      "publicDomainStatus": {
        "US": true,
        "EU": true,
        "Canada": true,
        "Australia": true,
        "Japan": true,
        "China": true,
        "India": true
      },
      "directQuotes": [
        {
          "quote": "my name is jeff",
          "location": "Book V, Chapter XI"
        }
      ]
    }
  ];

  useEffect(() => {
    const storedReadingList = JSON.parse(localStorage.getItem('readingList')) || [];
    setReadingList(storedReadingList);

    const initialQuotes = {};
    films.forEach((film) => {
      const randomQuote = getRandomQuote(film.directQuotes);
      if (randomQuote) {
        initialQuotes[film.title] = randomQuote.quote;
      }
    });
    setQuotes(initialQuotes);
  }, []);

  const isInReadingList = (title) => {
    return readingList.some(film => film.title === title);
  };

  const handleWatchClick = (e, title, link) => {
    e.stopPropagation();
    console.log(`Navigating to /watch/${encodeURIComponent(title)} with link:`, link); // Log the link being passed
    navigate(`/watch/${encodeURIComponent(title)}`, { state: { link } }); // Navigate to the film's watch page and pass the link
  };

  const handleLearnClick = (e, title) => {
    e.stopPropagation();
    navigate(`/learn/film/${encodeURIComponent(title)}`);
  };

  const handleToggleReadingList = (e, film) => {
    e.stopPropagation();
    let updatedReadingList;
    if (isInReadingList(film.title)) {
      updatedReadingList = readingList.filter(f => f.title !== film.title);
    } else {
      updatedReadingList = [...readingList, film];
    }
    setReadingList(updatedReadingList);
    localStorage.setItem('readingList', JSON.stringify(updatedReadingList));
  };

  const hasLearningMaterials = (film) => {
    return film.keyPoints || film.directQuotes || film.discussionQuestions || film.multipleChoiceQuiz;
  };

  const getRandomQuote = (quotes) => {
    if (quotes && quotes.length > 0) {
      const randomIndex = Math.floor(Math.random() * quotes.length);
      return quotes[randomIndex];
    }
    return null;
  };

  return (
    <div className="book-list">
      {films.map((film) => {
        const quote = quotes[film.title] || '';

        return (
          <div 
            key={film.title} 
            className="book-item" 
            onClick={(e) => {
              if (e.target.tagName !== 'A' && !e.target.classList.contains('film-button') && onSelect) {
                onSelect(film.title);
                localStorage.setItem(`lastOpened-${film.title}`, Date.now());
              }
            }}
          >
            <h3 style={{fontSize: '22px'}}>{film.title}</h3>
            <p>
              <strong>{Array.isArray(film.authors) ? 'Authors: ' : 'Author: '}</strong> 
              {Array.isArray(film.authors) ? (
                film.authors.map((author, index) => (
                  <span key={author}>
                    <Link to={`/author/${encodeURIComponent(author)}`} onClick={(e) => e.stopPropagation()}>
                      {author}
                    </Link>
                    {index < film.authors.length - 1 && ', '}
                  </span>
                ))
              ) : (
                <Link to={`/author/${encodeURIComponent(film.author)}`} onClick={(e) => e.stopPropagation()}>
                  {film.author}
                </Link>
              )}
            </p>
            <p><strong>Year:</strong> {film.year}</p>
            <p><strong>Subject:</strong> {film.subject}</p>
            <p>{film.description}</p>
            {quote && (
              <p><em>"{quote}"</em></p>
            )}
            <div className="book-buttons">
              <button id='bookButton' className="film-button watch-button" onClick={(e) => handleWatchClick(e, film.title, film.link)}>
                <Film /> Watch
              </button>
              {hasLearningMaterials(film) && (
                <button id='bookButton' className="film-button learn-button" onClick={(e) => handleLearnClick(e, film.title)}>
                  <Lightbulb /> Learn
                </button>
              )}
              <button id='bookButton'className="film-button toggle-reading-list-button" onClick={(e) => handleToggleReadingList(e, film)}>
                {isInReadingList(film.title) ? <Check /> : <Plus />} List
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FilmList;
