import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './NotePage.css';
import { Button, Form, FormControl } from 'react-bootstrap';
import { Bookshelf, PencilSquare } from 'react-bootstrap-icons';
import Footer from './Footer';

const NotePage = () => {
  const { title } = useParams();
  const [notes, setNotes] = useState([]);
  const [currentBook, setCurrentBook] = useState(null);
  const [showNote, setShowNote] = useState(false);
  const [note, setNote] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('/books.json')
      .then(response => response.json())
      .then(data => {
        const decodedTitle = decodeURIComponent(title);
        const matchedBook = data.find(book => book.title === decodedTitle);
        setCurrentBook(matchedBook);
      })
      .catch(error => console.error('Error fetching books:', error));
  }, [title]);

  useEffect(() => {
    const bookNotes = JSON.parse(localStorage.getItem(`notes-${title}`)) || [];
    // Sort notes by timestamp, newest first
    bookNotes.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    setNotes(bookNotes);
  }, [title]);

  const handleBackToLibrary = () => {
    navigate('/');
  };

  const handleDeleteNote = (index) => {
    setShowDeleteModal(true);
    setDeleteIndex(index);
  };

  const confirmDeleteNote = () => {
    const updatedNotes = [...notes];
    updatedNotes.splice(deleteIndex, 1);
    localStorage.setItem(`notes-${title}`, JSON.stringify(updatedNotes));
    setNotes(updatedNotes);
    setShowDeleteModal(false);
    setDeleteIndex(null);
  };

  const handleDismissDelete = () => {
    setShowDeleteModal(false);
    setDeleteIndex(null);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSaveNote = () => {
    const updatedNotes = [...notes];
    const now = new Date();
    const timestamp = now.toLocaleString();
    const location = localStorage.getItem(`location-${title}`) || '1';

    const newNote = {
      text: note,
      timestamp: timestamp,
      location: location,
    };

    updatedNotes.push(newNote);
    // Sort notes by timestamp, newest first
    updatedNotes.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    localStorage.setItem(`notes-${title}`, JSON.stringify(updatedNotes));
    setShowNote(false);
    setNote('');
    setNotes(updatedNotes);
  };

  const handleJournalClick = () => {
    setShowNote(true);
  };

  const handleDismissNote = () => {
    setShowNote(false);
    setNote('');
  };

  const handleBackClick = () => {
    const previousPage = localStorage.getItem('previousPage');
  
    if (previousPage && previousPage !== window.location.pathname) {
      navigate(previousPage);
    } else {
      navigate('/'); // Default to the home or library page
    }
  };

  const handleNoteClick = (location) => {
    navigate(`/book/${encodeURIComponent(title)}/${encodeURIComponent(location)}`);
  };

  if (!currentBook) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div id='spacer'></div>
      <div>
        <div className="author-page">
          <div id='authorHeading'>
            <Button id='authorBackButton' onClick={handleBackClick}>
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343">
                <path d="M624-96 240-480l384-384 68 68-316 316 316 316-68 68Z"/>
              </svg>
            </Button>
            <h3 style={{fontSize: '22px'}}>Notes for {currentBook.title}</h3>
            <div style={{marginLeft: 'auto', paddingRight: '10px'}}></div>
          </div>
          <div className="note-page-buttons">
            <Button onClick={handleJournalClick} id="standardButtonNav">
              <PencilSquare /> Add Note
            </Button>
          </div>
          {notes.length === 0 ? (
            <p>You have no notes or questions saved for this book.</p>
          ) : (
            notes.map((note, index) => (
              <div
                key={index}
                className="search-result"
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={() => handleNoteClick(note.location || '')}
              >
                <button 
                  className="delete-button" 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteNote(index);
                  }}
                  style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                  &times;
                </button>
                <div className="note-content">
                  {note.question && (
                    <div>
                      <p><strong>Q:</strong> {note.question}</p>
                      <p><strong>A:</strong> {note.answer}</p>
                    </div>
                  )}
                  {note.text && <p>{note.text}</p>}
                  <small><em>{note.timestamp}</em></small>
                </div>
              </div>
            ))
          )}
        </div>

        {showNote && (
          <div className="search-modal-overlay">
            <div className="search-modal">
              <button className="close-button" onClick={handleDismissNote}>
                &times;
              </button>
              <Form className="search-result-content">
                <Form.Group controlId="noteForm">
                  <Form.Label>Add a Note</Form.Label>
                  <FormControl
                    as="textarea"
                    rows={3}
                    value={note}
                    onChange={handleNoteChange}
                  />
                </Form.Group>
                <Button id='standardButton' onClick={handleSaveNote}>
                  Save Note
                </Button>
              </Form>
            </div>
          </div>
        )}

        {showDeleteModal && (
          <div className="search-modal-overlay">
            <div className="search-modal">
              <button className="close-button" onClick={handleDismissDelete}>
                &times;
              </button>
              <div className="search-result-content">
                <p>Are you sure you want to delete this note?</p>
                <Button id='standardButton' onClick={confirmDeleteNote}>
                  Delete
                </Button>
                <Button id='standardButton' onClick={handleDismissDelete}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default NotePage;
