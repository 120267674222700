import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BookList from './BookList';
import { Form, FormControl, Button } from 'react-bootstrap';
import { SortAlphaDownAlt } from 'react-bootstrap-icons';

const AuthorPage = () => {
  const { authorName } = useParams();
  const [books, setBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [isReverseAlphabetical, setIsReverseAlphabetical] = useState(false);
  useEffect(() => {
    // This will run when the component mounts or updates
    localStorage.setItem('previousPage', window.location.pathname);
  }, [authorName]); // or another dependency that makes sense for your logic
  const navigate = useNavigate();

  useEffect(() => {
    fetch('/books.json')
      .then(response => response.json())
      .then(data => {
        const lowerCaseAuthorName = decodeURIComponent(authorName).toLowerCase();
        const authorBooks = data.filter(book => 
          (Array.isArray(book.authors) && book.authors.some(author => author.toLowerCase() === lowerCaseAuthorName)) ||
          (book.author && book.author.toLowerCase() === lowerCaseAuthorName)
        );
        setBooks(authorBooks);
      })
      .catch(error => console.error('Error fetching books:', error));
  }, [authorName]);
  
  

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleReverseAlphabeticalClick = () => {
    setIsReverseAlphabetical(!isReverseAlphabetical);
  };

  const handleSubjectClick = (subject) => {
    setSelectedSubjects(prevSubjects =>
      prevSubjects.includes(subject)
        ? [] // Clear selection to show all books
        : [subject] // Set selected subject
    );
  };

  const handleAllClick = () => {
    setSelectedSubjects([]);
  };

  const handleBookSelect = (title) => {
    localStorage.setItem('previousPage', window.location.pathname);
    navigate(`/book/${encodeURIComponent(title)}`);
  };

  const handleReadClick = (title) => {
    localStorage.setItem('previousPage', window.location.pathname);
    navigate(`/book/${encodeURIComponent(title)}`);
  };

  const handleLearnClick = (title) => {
    localStorage.setItem('previousPage', window.location.pathname);
    navigate(`/learn/book/${encodeURIComponent(title)}`);
  };

  const handleBackClick = () => {
    const previousPage = localStorage.getItem('previousPage');
  
    if (previousPage && previousPage !== window.location.pathname) {
      navigate(previousPage);
    } else {
      navigate('/'); // Default to the home or library page
    }
  };

  // Extract unique subjects and handle subjects with slashes
  const subjects = Array.from(new Set(
    books.flatMap(book =>
      book.subject.split('/').map(sub => sub.trim())
    )
  ));

  // Sort subjects alphabetically
  const sortedSubjects = subjects.sort((a, b) => a.localeCompare(b));

  // Filter books based on search term and selected subjects
  const filteredBooks = books.filter(book => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const matchesSearchTerm = 
      book.title.toLowerCase().includes(lowerCaseSearchTerm) ||
      book.author.toLowerCase().includes(lowerCaseSearchTerm);
    const matchesSubject = selectedSubjects.length === 0
      ? true
      : book.subject.split('/').some(sub => selectedSubjects.includes(sub.trim()));
    return matchesSearchTerm && matchesSubject;
  });

  // Sort books in reverse alphabetical order if isReverseAlphabetical is true
  const sortedBooks = isReverseAlphabetical 
    ? [...filteredBooks].sort((a, b) => b.title.localeCompare(a.title)) 
    : filteredBooks.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <>
      <div id='spacer'></div>

      <div className="author-page">
        <div id='authorHeading'>
  
            <Button id='authorBackButton' onClick={handleBackClick}>
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M624-96 240-480l384-384 68 68-316 316 316 316-68 68Z"/></svg>
            </Button>
      
       
        <h3 style={{fontSize: '22px'}} id="aboutHeading">{decodeURIComponent(authorName)}</h3>
        <div style={{marginLeft: 'auto', paddingRight: '10px'}}></div>
        </div>
        {/* <div id='search' className="search-bar">
          <Form className="w-100" onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="d-flex justify-content-center align-items-center w-100">
              <FormControl
                type="text"
                placeholder="Search the author's books"
                className="mr-sm-2 custom-search-input"
                value={searchTerm}
                onChange={handleSearchInputChange}
                id="searchInput"
              />
            </Form.Group>
          </Form>
        </div> */}
        <div className="subject-buttons">
          <Button
            id='allButton'
            onClick={handleAllClick}
            className={selectedSubjects.length === 0 ? 'active' : ''}
          >
            All
          </Button>
          {sortedSubjects.map(subject => (
            <Button
              id='sortButton'
              key={subject}
              onClick={() => handleSubjectClick(subject)}
              className={selectedSubjects.includes(subject) ? 'active' : ''}
            >
              {subject}
            </Button>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
          <Button
            id='alphabeticalButton'
            onClick={handleReverseAlphabeticalClick}
            className={isReverseAlphabetical ? 'active' : ''}
          >
            <SortAlphaDownAlt className="sort-icon"/>
          </Button>
        </div>
        <BookList 
          books={sortedBooks} 
          onSelect={handleBookSelect} 
          onRead={handleReadClick} 
          onLearn={handleLearnClick} 
        />
      </div>
    </>
  );
};

export default AuthorPage;
