import React from 'react';
import Footer from './Footer';
import './InfoPage.css';
const Privacy = () => {
  const pageStyle = {
    backgroundColor: '#f0f0f0',
    color: 'black',
    padding: '20px',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const textStyle = {
    maxWidth: '600px',
    textAlign: 'center',
  };



  return (
    <>
          <h1 id='aboutHeading' onClick={(e) => {
                  e.preventDefault();
                  window.open('/', "_self");
                }}>Book Haven</h1>
    <div style={pageStyle}>
    <div style={textStyle}>
        <h1>Privacy</h1>
        <p>

        We care about your privacy which is why bookhaven.org does not collect any personal information unless you provide it to us. No data is ever sold and our sites are ad free. We use anonymous privacy centric analytics provided by <a id='newssourceLink' newssourceLink href="https://plausible.io/" style={{ textDecoration: 'underline' }}> plausible.io </a>.
        </p>
      </div>
    
    </div>
    <Footer/>

    </>
  );
};

export default Privacy;
