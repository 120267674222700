import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { EpubView } from 'react-reader';
import Footer from './Footer';
import QuizComponent from './QuizComponent';
import './LearnPage.css'; // Reuse the same CSS file
import { Book, Bookshelf } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

const LearnPage = () => {
  const { title, page } = useParams();
  const navigate = useNavigate();
  const [book, setBook] = useState(null);
  const [location, setLocation] = useState(decodeURIComponent(page || localStorage.getItem(`location-${title}`) || '1'));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [revealedAnswers, setRevealedAnswers] = useState([]); // Initialize revealedAnswers state
  const epubRef = useRef(null);

  useEffect(() => {
    if (!title) {
      setError('Book title is missing');
      setLoading(false);
      return;
    }

    const decodedTitle = decodeURIComponent(title);

    fetch('/books.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        const selectedBook = data.find(book => book.title === decodedTitle);
        if (selectedBook) {
          setBook(selectedBook);
        } else {
          setError('Book not found');
        }
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [title]);

  const handleLocationChanged = (newLocation) => {
    const encodedLocation = encodeURIComponent(newLocation);
    setLocation(newLocation);
    localStorage.setItem(`location-${title}`, newLocation);
    navigate(`/learn/book/${encodeURIComponent(title)}/${encodedLocation}`);
  };

  const handleReturnToLibrary = () => {
    const previousPage = localStorage.getItem('previousPage');
  
    if (previousPage && previousPage !== window.location.pathname) {
      navigate(previousPage);
    } else {
      navigate('/'); // Default to the home or library page
    }
  };
  
  

  const handleReadBook = () => {
    // Store the current page (LearnPage) in localStorage
    localStorage.setItem('previousPage', window.location.pathname);
  
    // Navigate to the BookPage
    navigate(`/book/${encodeURIComponent(title)}/${encodeURIComponent(location)}`);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const toggleAnswer = (index) => {
    setRevealedAnswers((prev) => {
      const newRevealedAnswers = [...prev];
      newRevealedAnswers[index] = !newRevealedAnswers[index];
      return newRevealedAnswers;
    });
  };

  const handleBackClick = () => {
    const previousPage = localStorage.getItem('previousPage');
  
    if (previousPage && previousPage !== window.location.pathname) {
      navigate(previousPage);
    } else {
      navigate('/'); // Default to the home or library page
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!book) {
    return <div>Book not found</div>;
  }

  return (
    <>
      <div id='spacer'></div>
<div>
<div className="author-page">
        <div id='authorHeading'>
  
            <Button  id='authorBackButton' onClick={handleBackClick}>
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M624-96 240-480l384-384 68 68-316 316 316 316-68 68Z"/></svg>
            </Button>
          <h3 style={{fontSize: '22px'}} >{book.title}</h3>

        <div style={{marginLeft: 'auto', paddingRight: '10px'}}></div>
        </div>
          <div className="book-details">
            <p>
              <strong>Author:</strong>{" "}
              <Link
    to={`/author/${encodeURIComponent(book.author)}`}
    onClick={(e) => {
      e.stopPropagation();
      localStorage.setItem('previousPage', window.location.pathname);
    }}
  >
    {book.author}
  </Link>
            </p>
            <p><strong>Year:</strong> {book.year}</p>
            <p><strong>Subject:</strong> {book.subject}</p>

            {book.description && (
  <p>
    <strong>Description:</strong> {book.description}
  </p>
)}

            {book.summary && (
  <p>
    <strong>Summary:</strong> {truncateText(book.summary, 200)}
    <button id="standardButton" onClick={() => setShowSummaryModal(true)}>
      Read More
    </button>
  </p>
)}



            
         

          {book.keyPoints && (
            <div className="key-points">
              <h2>Key Points</h2>
              <ul>
                {book.keyPoints.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          )}

{book.directQuotes && (
  <div className="direct-quotes">
    <h2>Quotes</h2>
    <ul>
      {book.directQuotes.map((quote, index) => (
        <li key={index}>
          <blockquote>“{quote.quote}”</blockquote>
          <cite>{quote.location}</cite>
        </li>
      ))}
    </ul>
  </div>
)}

          {book.discussionQuestions && (
            <div className="discussion-questions">
              <h2>Discussion Questions</h2>
              <ul>
                {book.discussionQuestions.map((question, index) => (
                  <li key={index}>
                    <br /><strong>Q:</strong> {question.question}
                    <br /><br />
                    {revealedAnswers[index] && <strong>A:</strong>}
                    {revealedAnswers[index] && (
                      <span> {question.answer}</span>
                    )}
                    <button id="standardButton" onClick={() => toggleAnswer(index)}>
                      {revealedAnswers[index] ? "Hide Answer" : "Show Answer"}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {book.multipleChoiceQuiz && (
            <div className="multiple-choice-quiz">
              <QuizComponent quiz={book.multipleChoiceQuiz} />
            </div>
          )}
        </div>
      </div>

      {showSummaryModal && (
        
  <div className="summary-modal-overlay">
    
    <div className="summary-modal">

      <button className="close-button" onClick={() => setShowSummaryModal(false)}>
        &times;
      </button>
      <div className="summary-result-content">
        <h2>Summary</h2>
        <p>{book.summary}</p>
         <button id="standardButton" onClick={() => setShowSummaryModal(false)}>
          Close
        </button>
      </div>
    </div>
  </div>
)}
 </div>
      <Footer />
    </>
  );
};

export default LearnPage;
