import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { HeartFill, ArrowLeft } from 'react-bootstrap-icons'; // Added ArrowLeft icon for the back button
import Footer from './Footer';
import './InfoPage.css';

const About = () => {
  useEffect(() => {
    localStorage.setItem('previousNonBookPage', '/about');
  }, []);
  const pageStyle = {
    backgroundColor: '#f0f0f0',
    color: 'black',
    padding: '20px',
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  };

  const textStyle = {
    maxWidth: '600px',
    textAlign: 'center',
  };

  return (
    <>
      <div id="spacer"></div>
      <h1
        id="infoHeading"
        onClick={(e) => {
          e.preventDefault();
          window.open('/', '_self');
        }}
      >
        Book Haven
      </h1>
      <div style={pageStyle}>
        <div style={contentStyle}>
          <h1>About</h1>
          <p>
            Book Haven is a free digital library and ebook reader that gives you easy access to classic books in the public domain. Brought to you by the{' '}
            <a id="newssourceLink" href="https://govglance.foundation/">
              Gov Glance Foundation
            </a>
          </p>
          <Button
            id="standardButton"
            onClick={(e) => {
              e.preventDefault();
              window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', '_blank');
            }}
          >
            <HeartFill id="donateIcon" /> Donate
          </Button>
          <div style={{ marginTop: '20px' }}>
            <Button
              id="standardButton"
              onClick={() => window.history.back()}
            >
              <ArrowLeft /> Go Back
            </Button>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default About;
