import React, { useState, useEffect } from 'react';
import { Book, Lightbulb, Check, Plus } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';

const BookList = ({ books, onSelect }) => {
  const navigate = useNavigate();
  const [readingList, setReadingList] = useState([]);
  const [quotes, setQuotes] = useState({});

  useEffect(() => {
    const storedReadingList = JSON.parse(localStorage.getItem('readingList')) || [];
    setReadingList(storedReadingList);

    // Generate quotes for each book when component mounts
    const initialQuotes = {};
    books.forEach((book) => {
      const randomQuote = getRandomQuote(book.directQuotes);
      if (randomQuote) {
        initialQuotes[book.title] = randomQuote.quote;
      }
    });
    setQuotes(initialQuotes);
  }, [books]);

  const isInReadingList = (title) => {
    return readingList.some(book => book.title === title);
  };

  const handleReadClick = (e, title) => {
    e.stopPropagation();
    const savedLocation = localStorage.getItem(`location-${title}`) || '1';
    localStorage.setItem(`lastOpened-${title}`, Date.now());
    window.location.href = `/book/${encodeURIComponent(title)}/${encodeURIComponent(savedLocation)}`;
  };

  const handleLearnClick = (e, title) => {
    e.stopPropagation();
    navigate(`/learn/book/${encodeURIComponent(title)}`);
  };

  const handleToggleReadingList = (e, book) => {
    e.stopPropagation();
    let updatedReadingList;
    if (isInReadingList(book.title)) {
      updatedReadingList = readingList.filter(b => b.title !== book.title);
    } else {
      updatedReadingList = [...readingList, book];
    }
    setReadingList(updatedReadingList);
    localStorage.setItem('readingList', JSON.stringify(updatedReadingList));
  };

  const hasLearningMaterials = (book) => {
    return book.keyPoints || book.directQuotes || book.discussionQuestions || book.multipleChoiceQuiz;
  };

  const getRandomQuote = (quotes) => {
    if (quotes && quotes.length > 0) {
      const randomIndex = Math.floor(Math.random() * quotes.length);
      return quotes[randomIndex];
    }
    return null;
  };

  return (
    <div className="book-list">
      {books.map((book) => {
        const quote = quotes[book.title] || '';

        return (
          <div 
            key={book.title} 
            className="book-item" 
            onClick={(e) => {
              if (e.target.tagName !== 'A' && !e.target.classList.contains('book-button') && onSelect) {
                onSelect(book.title);
                localStorage.setItem(`lastOpened-${book.title}`, Date.now());
              }
            }}
          >
            <h3 style={{fontSize: '22px'}}>{book.title}</h3>
            <p>
  <strong>{Array.isArray(book.authors) ? 'Authors: ' : 'Author: '}</strong> 
  {Array.isArray(book.authors) ? (
    book.authors.map((author, index) => (
      <span key={author}>
        <Link to={`/author/${encodeURIComponent(author)}`} onClick={(e) => e.stopPropagation()}>
          {author}
        </Link>
        {index < book.authors.length - 1 && ', '}
      </span>
    ))
  ) : (
    <Link to={`/author/${encodeURIComponent(book.author)}`} onClick={(e) => e.stopPropagation()}>
      {book.author}
    </Link>
  )}
</p>
            <p><strong>Year:</strong> {book.year}</p>
            <p><strong>Subject:</strong> {book.subject}</p>
            <p>{book.description}</p>
            {quote && (
              <p><em>"{quote}"</em></p>
            )}
            <div className="book-buttons">
            <button id='bookButton' className="book-button read-button" onClick={(e) => handleReadClick(e, book.title)}>
                <Book /> Read
              </button>
              {hasLearningMaterials(book) && (
                <button id='bookButton' className="book-button learn-button" onClick={(e) => handleLearnClick(e, book.title)}>
                  <Lightbulb /> Learn
                </button>
              )}
      
              <button id='bookButton' className="book-button toggle-reading-list-button" onClick={(e) => handleToggleReadingList(e, book)}>
                {isInReadingList(book.title) ? <Check /> : <Plus />} List
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BookList;
