import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BookList from './BookList';
import './Home.css';
import Footer from './Footer';
import { Form, FormControl, Button } from 'react-bootstrap';
import { Collection, Shuffle, SortAlphaDown, SortAlphaDownAlt } from 'react-bootstrap-icons';

const Home = () => {
  const [books, setBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [sortMode, setSortMode] = useState('shuffle'); // Default to shuffle
  const navigate = useNavigate();

  useEffect(() => {
    // Load saved preferences from localStorage
    const savedSubjects = JSON.parse(localStorage.getItem('selectedSubjects'));
    const savedSortMode = localStorage.getItem('sortMode');
    if (savedSubjects) setSelectedSubjects(savedSubjects);
    if (savedSortMode) setSortMode(savedSortMode);

    fetch('/books.json')
      .then(response => response.json())
      .then(data => {
        if (savedSortMode === 'shuffle') {
          const shuffledBooks = data.sort(() => Math.random() - 0.5);
          setBooks(shuffledBooks);
        } else {
          setBooks(data);
        }
      })
      .catch(error => console.error('Error fetching books:', error));
  }, []);

  const handleSortButtonClick = () => {
    let newSortMode;
    let sortedBooks;

    if (sortMode === 'shuffle') {
      newSortMode = 'aToZ';
      sortedBooks = [...books].sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortMode === 'aToZ') {
      newSortMode = 'zToA';
      sortedBooks = [...books].sort((a, b) => b.title.localeCompare(a.title));
    } else {
      newSortMode = 'shuffle';
      sortedBooks = [...books].sort(() => Math.random() - 0.5);
    }

    setSortMode(newSortMode);
    setBooks(sortedBooks);
    localStorage.setItem('sortMode', newSortMode); // Save to localStorage
  };

  const handleBookSelect = (title) => {
    localStorage.setItem('previousPage', window.location.pathname);
    navigate(`/book/${encodeURIComponent(title)}`);
  };

  const handleAuthorClick = (author) => {
    localStorage.setItem('previousPage', window.location.pathname);
    navigate(`/author/${encodeURIComponent(author)}`);
  };

  const handleLearnPageClick = (title) => {
    localStorage.setItem('previousPage', window.location.pathname);
    navigate(`/learn/book/${encodeURIComponent(title)}`);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubjectClick = (subject) => {
    const updatedSubjects = selectedSubjects.includes(subject)
      ? [] // Clear selection to show all books
      : [subject]; // Set selected subject

    setSelectedSubjects(updatedSubjects);
    localStorage.setItem('selectedSubjects', JSON.stringify(updatedSubjects));
  };

  const handleAllClick = () => {
    setSelectedSubjects([]);
    localStorage.setItem('selectedSubjects', JSON.stringify([]));
  };

  const handleReadingListClick = () => {
    localStorage.setItem('previousPage', window.location.pathname);
    navigate('/readinglist');
  };

  // Extract unique subjects and handle subjects with slashes
  const subjects = Array.from(new Set(
    books.flatMap(book =>
      book.subject.split('/').map(sub => sub.trim())
    )
  ));

  // Sort subjects alphabetically
  const sortedSubjects = subjects.sort((a, b) => a.localeCompare(b));

  // Filter and sort books based on search term, selected subjects, and sort order
  const filteredAndSortedBooks = books
    .filter(book => {
      const title = book.title?.toLowerCase() || '';
      const author = book.author?.toLowerCase() || '';
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const matchesSearchTerm = title.includes(lowerCaseSearchTerm) || author.includes(lowerCaseSearchTerm);
      const matchesSubject = selectedSubjects.length === 0
        ? true
        : book.subject.split('/').some(sub => selectedSubjects.includes(sub.trim()));
      return matchesSearchTerm && matchesSubject;
    });

  return (
    <>
      <div id='spacer'></div>
      <div>
        <div id='searchHome' className="search-bar">
          <Form className="w-100" onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="d-flex justify-content-center align-items-center w-100">
              <FormControl
                type="text"
                placeholder="Search the library"
                className="mr-sm-2 custom-search-input"
                value={searchTerm}
                onChange={handleSearchInputChange}
                id="searchInputHome"
              />
            </Form.Group>
          </Form>
        </div>

        <div className="subject-buttons-wrapper">
          <div className="subject-buttons">
            <Button
              id='allButton'
              onClick={handleAllClick}
              className={selectedSubjects.length === 0 ? 'active' : ''}
            >
              All
            </Button>
            {sortedSubjects.map(subject => (
              <Button
                id='sortButton'
                key={subject}
                onClick={() => handleSubjectClick(subject)}
                className={selectedSubjects.includes(subject) ? 'active' : ''}
              >
                {subject}
              </Button>
            ))}
          </div>
        </div>

        <div className="book-section-wrapper">
          <div className="controls">
            <Button
              id='readingListButton'
              onClick={handleReadingListClick}
              className="ml-2"
            >
              <Collection className="sort-icon" /> List
            </Button>
            <Button
               id='readingListButton'
              onClick={handleSortButtonClick}
              className={sortMode === 'shuffle' ? 'active' : ''}
            >
              {sortMode === 'shuffle' && <Shuffle className="sort-icon" />}
              {sortMode === 'aToZ' && <SortAlphaDown className="sort-icon" />}
              {sortMode === 'zToA' && <SortAlphaDownAlt className="sort-icon" />}
            </Button>
          </div>

          <BookList books={filteredAndSortedBooks} onSelect={handleBookSelect} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
