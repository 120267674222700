import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { EpubView } from 'react-reader';
import Footer from './Footer';
import './BookPage.css';
import { Bookshelf, Lightbulb, Journal, PencilSquare, Collection } from 'react-bootstrap-icons';
import SearchBar from './OpenAI';
import { Form, Button, FormControl } from 'react-bootstrap';
import { useSwipeable } from 'react-swipeable';

const PrevIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M10 12.796V3.204L4.519 8zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753"/>
  </svg>
);

const NextIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M6 12.796V3.204L11.481 8zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753"/>
  </svg>
);

const BookPage = () => {
  const { title, page } = useParams();
  const navigate = useNavigate();
  const [book, setBook] = useState(null);
  const [location, setLocation] = useState(decodeURIComponent(page || localStorage.getItem(`location-${title}`) || '1'));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [note, setNote] = useState('');
  const [isInReadingList, setIsInReadingList] = useState(false);
  const epubRef = useRef(null);
  const [previousPage, setPreviousPage] = useState('/');

  useEffect(() => {
    if (!title) {
      setError('Book title is missing');
      setLoading(false);
      return;
    }

    const decodedTitle = decodeURIComponent(title);
    
    const storedPreviousPage = localStorage.getItem('previousPage') || '/';
    setPreviousPage(storedPreviousPage);

    fetch('/books.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        const selectedBook = data.find(book => book.title === decodedTitle);
        if (selectedBook) {
          setBook(selectedBook);
          const readingList = JSON.parse(localStorage.getItem('readingList')) || [];
          if (readingList.some(b => b.title === selectedBook.title)) {
            setIsInReadingList(true);
          }
        } else {
          setError('Book not found');
        }
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [title]);

  const handleLocationChanged = (newLocation) => {
    const encodedLocation = encodeURIComponent(newLocation);
    setLocation(newLocation);
    localStorage.setItem(`location-${title}`, newLocation);
    navigate(`/book/${encodeURIComponent(title)}/${encodedLocation}`);
  };

  const handleNext = () => {
    if (epubRef.current) {
      epubRef.current.nextPage();
    }
  };

  const handlePrev = () => {
    if (epubRef.current) {
      epubRef.current.prevPage();
    }
  };

  const handleReturnToLibrary = () => {
    const previousPage = localStorage.getItem('previousPage');
    if (previousPage && previousPage !== window.location.pathname) {
      navigate(previousPage);
      localStorage.removeItem('previousPage'); // Clear the entry after use
    } else {
      navigate('/'); // Default to the home or library page
    }
  };

  const handleLearnClick = () => {
    localStorage.setItem('previousPage', window.location.pathname);
    navigate(`/learn/book/${encodeURIComponent(title)}`);
  };

  const handleSwipeLeft = () => {
    if (epubRef.current) {
      epubRef.current.nextPage();
    }
  };

  const handleSwipeRight = () => {
    if (epubRef.current) {
      epubRef.current.prevPage();
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
    delta: 10,
  });

  const handleToggleReadingList = () => {
    const readingList = JSON.parse(localStorage.getItem('readingList')) || [];
    if (isInReadingList) {
      const updatedList = readingList.filter(b => b.title !== book.title);
      localStorage.setItem('readingList', JSON.stringify(updatedList));
      setIsInReadingList(false);
    } else {
      readingList.push(book);
      localStorage.setItem('readingList', JSON.stringify(readingList));
      setIsInReadingList(true);
    }
  };

  const handleNoteClick = () => {
    localStorage.setItem('previousPage', window.location.pathname);
    navigate(`/${encodeURIComponent(title)}/notes`);
  };

  const handleAddNoteClick = () => {
    setShowNoteModal(true);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSaveNote = () => {
    if (!note.trim()) {
      alert("Note can't be empty!");
      return;
    }
    const notes = JSON.parse(localStorage.getItem(`notes-${title}`)) || [];
    const now = new Date();
    const timestamp = now.toLocaleString();
    const newNote = {
      text: note,
      location: location,
      timestamp: timestamp,
    };
    notes.push(newNote);
    localStorage.setItem(`notes-${title}`, JSON.stringify(notes));
    setShowNoteModal(false);
    setNote('');
  };

  const handleDismissNote = () => {
    setShowNoteModal(false);
    setNote(''); // Clear the note input when dismissed
  };

  const hasLearningMaterials = (book) => {
    return book.keyPoints || book.directQuotes || book.discussionQuestions || book.multipleChoiceQuiz;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!book) {
    return <div>Book not found</div>;
  }

  return (
    <>
      <div id='spacer'></div>
      <SearchBar bookTitle={book.title} />
      <div className="book-page">
        <div {...handlers} className="swipeable-container" style={{ height: '67vh', position: 'relative', color: 'transparent', 
        touchAction: 'none'}}>
          <div style={{ height: '100%', width: '100%', pointerEvents: 'none' }}>
            {book.link ? (
              <EpubView
                ref={epubRef}
                url={book.link}
                location={location}
                locationChanged={handleLocationChanged}
              />
            ) : (
              <div>No EPUB link available</div>
            )}
          </div>

        </div>
        <div id='pageNavigation'>
          <button id="navigationButton" onClick={handlePrev} className="nav-button" aria-label="Previous Page">
            <PrevIcon />
          </button>
          <button id="navigationButton" onClick={handleNext} className="nav-button" aria-label="Next Page">
            <NextIcon />
          </button>
        </div>
        <div className="navigation-controls">
          <div className="other-buttons-row">
            <button id="standardButtonNav" onClick={handleNoteClick} className="nav-button" aria-label="Notes">
              <Journal />
              Notes
            </button>
            <button id="standardButtonNav" onClick={handleAddNoteClick} className="nav-button" aria-label="Add Note">
              <PencilSquare />
              Add Note
            </button>
            <button id="standardButtonNav" onClick={handleReturnToLibrary} className="nav-button">
              {previousPage === '/' ? <Bookshelf /> : 
              (previousPage === '/readinglist' ? <Collection /> : 
              (previousPage === `/learn/book/${encodeURIComponent(title)}` ? 
              <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#434343">
                <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/>
              </svg> : 
              (previousPage.startsWith('/author/') ? 
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343">
                <path d="M432-240h96v-144h48v-144q0-18-24.5-33T480-576q-47 0-71.5 15T384-528v144h48v144Zm48 144q-78.72 0-148.8-30.24-70.08-30.24-122.4-82.56-52.32-52.32-82.56-122.4Q96-401.28 96-480q0-79.68 30.24-149.28T208.8-751.2q52.32-52.32 122.4-82.56Q401.28-864 480-864q79.68 0 149.28 30.24T751.2-751.2q52.32 52.32 82.56 121.92Q864-559.68 864-480q0 78.72-30.24 148.8-30.24 70.08-82.56 122.4-52.32 52.32-121.92 82.56Q559.68-96 480-96Zm.23-72Q610-168 701-259.23t91-221Q792-610 700.77-701t-221-91Q350-792 259-700.77t-91 221Q168-350 259.23-259t221 91ZM480-624q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm0 144Z"/>
              </svg> : 
              <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#434343">
                <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/>
              </svg>)))}
              {previousPage === '/' ? 'Library' : 
              (previousPage === '/readinglist' ? 'List' : 
              (previousPage === `/learn/book/${encodeURIComponent(title)}` ? 'Back' : 
              (previousPage.startsWith('/author/') ? 'Author' : 'Back')))}
            </button>
            {hasLearningMaterials(book) && (
              <button id="standardButtonNav" onClick={handleLearnClick} className="nav-button" aria-label="Learn More">
                <Lightbulb /> Learn
              </button>
            )}
          </div>
        </div>
      </div>


      {showNoteModal && (
        <div className="search-modal-overlay">
          <div className="search-modal">
          <button className="close-button" onClick={handleDismissNote}>
              &times;
            </button>
            <Form className="search-result-content">
              <Form.Group controlId="noteForm">
                <Form.Label>Add a Note</Form.Label>
                <FormControl
                  as="textarea"
                  rows={3}
                  value={note}
                  onChange={handleNoteChange}
                />
              </Form.Group>
              <Button id='standardButton'  onClick={handleSaveNote}>Save</Button>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default BookPage;
