import React, { useState } from 'react';
import OpenAI from 'openai';
import { Form, FormControl, Button, Spinner } from 'react-bootstrap';
import './AI.css';
import { CaretLeft, Search } from 'react-bootstrap-icons';
import { IonSpinner } from '@ionic/react';
const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

const openai = new OpenAI({
  apiKey,
  dangerouslyAllowBrowser: true
});

const SearchBar = ({ bookTitle }) => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState(null);
  const [showBubble, setShowBubble] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSearchInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    if (!query.trim()) {
      alert("Please enter a question about the book.");
      return;
    }

    setLoading(true);
    try {
      const apiResponse = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: `Answer questions in context of the book mentioned and provide definitions to words. Do not spoil the endings of books. Do not repeat prompt in response. Book: ${bookTitle}, Question: ${query}.`,
          },
        ],
        temperature: 1,
        max_tokens: 100,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      });

      const answer = apiResponse.choices[0].message.content;
      setResponse(answer);
      setShowBubble(true);

      // Save the question and answer as a note
      saveNote(query, answer);

    } catch (error) {
      console.error("Error fetching data from OpenAI:", error);
    } finally {
      setLoading(false);
    }
  };

  const saveNote = (question, answer) => {
    const notes = JSON.parse(localStorage.getItem(`notes-${bookTitle}`)) || [];
    
    // Get the current date and time
    const now = new Date();
    const timestamp = now.toLocaleString(); // This will format the date and time as a string
    
    const newNote = {
      question: question,
      answer: answer,
      timestamp: timestamp, // Save the date and time the note was taken
    };
    
    notes.push(newNote);
    localStorage.setItem(`notes-${bookTitle}`, JSON.stringify(notes));
  };

  const handleDismiss = () => {
    setShowBubble(false);
    setResponse(null);
    setQuery('');
  };

  return (
    <div className="search-container">
            {/* <button id="backButton"  className="nav-button">
            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#434343"><path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/></svg>
            </button> */}
      <div id='search' className="search-bar">
        <Form className="w-100" onSubmit={handleSearch}>
          <Form.Group >
            <FormControl
              type="text"
              placeholder={`Ask a question about "${bookTitle}"`}
              className="mr-sm-2 custom-search-input"
              value={query}
              onChange={handleSearchInputChange}
              id="searchInput"
            />
            <Button
              id='standardButtonSearch'
              type="submit"
              disabled={loading}
              className="ml-2"
            >
              {loading ? <IonSpinner color='black'/> : <Search/>}
            </Button>
          </Form.Group>
        </Form>
      </div>

      {showBubble && (
        <div className="search-modal-overlay">
          <div className="search-modal">
            <button className="close-button" onClick={handleDismiss}>
              &times;
            </button>
            <div className="search-result-content">
              {response}
              <br></br>
              *AI generated content may contain mistakes
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
