import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Footer from './Footer';
import './BookPage.css'; // Adjust the styles as necessary

const FilmPage = () => {
  const { nameOfFilm } = useParams();
  const location = useLocation();
  const { link } = location.state || {}; // Access the link from the state

  if (!link) {
    return <div>Error: No video link provided.</div>;
  }

  return (
    <>
      <div id='spacer'></div>
      <div className="film-page">
        <h2>{nameOfFilm}</h2>
        {/* Vimeo Video Embed */}
        <div style={{ padding: '50% 0 0 0', position: 'relative' }}>
          <iframe
            src={link} 
            borderRadius= '10px'
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
            title={nameOfFilm}
          ></iframe>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FilmPage;
