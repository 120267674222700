import React, { useState } from 'react';
import { Check } from 'react-bootstrap-icons';
import './Quizz.css';
const QuizComponent = ({ quiz }) => {
  const [selectedAnswers, setSelectedAnswers] = useState(Array(quiz.length).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswerSelect = (questionIndex, choiceIndex) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[questionIndex] = choiceIndex;
    setSelectedAnswers(updatedAnswers);
  };

  const gradeQuiz = () => {
    let correctCount = 0;
    quiz.forEach((question, index) => {
      if (selectedAnswers[index] === question.choices.indexOf(question.correctAnswer)) {
        correctCount += 1;
      }
    });
    setScore(correctCount);
    setShowResults(true);
  };

  const retryQuiz = () => {
    setSelectedAnswers(Array(quiz.length).fill(null));
    setShowResults(false);
    setScore(0);
  };

  return (
    <div className="quiz-component">
      <h2>Quiz</h2>
      {!showResults ? (
        <div>
          {quiz.map((question, qIndex) => (
            <div key={qIndex} className="question">
              <p>{question.question}</p>
              <ul>
                {question.choices.map((choice, cIndex) => (
                  <li key={cIndex}>
                    <label>
                      <input
                        type="radio"
                        name={`question-${qIndex}`}
                        value={choice}
                        checked={selectedAnswers[qIndex] === cIndex}
                        onChange={() => handleAnswerSelect(qIndex, cIndex)}
                      />
                      {choice}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <button id='bookButton' onClick={gradeQuiz}><Check/> Complete</button>
        </div>
      ) : (
        <div>
          <h3>
            Your Score: {score}/{quiz.length}
          </h3>
          {score === quiz.length ? (
            <p>Perfect score! Well done!</p>
          ) : (
            <div>
              <p>Not a perfect score. Try again!</p>
              <button id='bookButton' onClick={retryQuiz}> Retry</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuizComponent;
