import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Book, Bookshelf, Lightbulb, X } from 'react-bootstrap-icons';
import Footer from './Footer';
import { Button } from 'react-bootstrap';

const ReadingListPage = () => {
  const navigate = useNavigate();
  const [readingList, setReadingList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTitle, setDeleteTitle] = useState(null);

  useEffect(() => {
    const list = JSON.parse(localStorage.getItem('readingList')) || [];
    const sortedList = list.sort((a, b) => {
      const lastOpenedA = parseInt(localStorage.getItem(`lastOpened-${a.title}`), 10) || 0;
      const lastOpenedB = parseInt(localStorage.getItem(`lastOpened-${b.title}`), 10) || 0;
      return lastOpenedB - lastOpenedA;
    });
    setReadingList(sortedList);
  }, []);

  const handleReadClick = (e, title) => {
    e.stopPropagation();
    localStorage.setItem('previousPage', window.location.pathname); // Save current page as previousPage
    const savedLocation = localStorage.getItem(`location-${title}`) || '1';
    localStorage.setItem(`lastOpened-${title}`, Date.now());
    navigate(`/book/${encodeURIComponent(title)}/${encodeURIComponent(savedLocation)}`);
  };
  
  const handleLearnClick = (e, title) => {
    e.stopPropagation();
    localStorage.setItem('previousPage', window.location.pathname); // Save current page as previousPage
    navigate(`/learn/book/${encodeURIComponent(title)}`);
  };
  
  const handleBookItemClick = (e, title) => {
    if (e.target.tagName !== 'A' && !e.target.classList.contains('book-button')) {
      localStorage.setItem('previousPage', window.location.pathname); // Save current page as previousPage
      localStorage.setItem(`lastOpened-${title}`, Date.now());
      navigate(`/book/${encodeURIComponent(title)}`);
    }
  };

  const handleRemoveBook = (e, title) => {
    e.stopPropagation();
    setDeleteTitle(title);
    setShowDeleteModal(true);
  };

  const confirmDeleteBook = () => {
    const updatedList = readingList.filter(book => book.title !== deleteTitle);
    setReadingList(updatedList);
    localStorage.setItem('readingList', JSON.stringify(updatedList));
    setShowDeleteModal(false);
    setDeleteTitle(null);
  };

  const handleDismissDelete = () => {
    setShowDeleteModal(false);
    setDeleteTitle(null);
  };

  const hasLearningMaterials = (book) => {
    return book.keyPoints || book.directQuotes || book.discussionQuestions || book.multipleChoiceQuiz;
  };

  const handleBackClick = () => {
    const previousPage = localStorage.getItem('previousPage');
  
    if (previousPage && previousPage !== window.location.pathname) {
      navigate(previousPage);
    } else {
      navigate('/'); // Default to the home or library page
    }
  };

  return (
    <>
      <div id='spacer'></div>
     <div>
      <div className="author-page">
        <div id='authorHeading'>
  
            <Button  id='authorBackButton' onClick={handleBackClick}>
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#434343"><path d="M624-96 240-480l384-384 68 68-316 316 316 316-68 68Z"/></svg>
            </Button>
        <h3 style={{fontSize: '22px'}} id='aboutHeading'>Reading List</h3>
        <div style={{marginLeft: 'auto', paddingRight: '10px'}}></div>
        </div>
        {readingList.length > 0 ? (
          <div className="book-list">
            {readingList.map((book) => (
              <div 
                key={book.title} 
                className="book-item" 
                onClick={(e) => handleBookItemClick(e, book.title)}
                style={{ position: 'relative' }} // Added style for positioning
              >
                <button 
                  className="remove-button" 
                  onClick={(e) => handleRemoveBook(e, book.title)}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <X size={20} />
                </button>
                <h3 style={{fontSize: '22px'}}>{book.title}</h3>
                <p>
                  <strong>Author: </strong>
                  <Link to={`/author/${encodeURIComponent(book.author)}`} onClick={(e) => e.stopPropagation()}>
                    {book.author}
                  </Link>
                </p>
                <p><strong>Year:</strong> {book.year}</p>
                <p><strong>Subject:</strong> {book.subject}</p>
                <p>{book.description}</p>
                <div className="book-buttons">
                  {hasLearningMaterials(book) && (
                    <button id='bookButton' className="book-button learn-button" onClick={(e) => handleLearnClick(e, book.title)}>
                      <Lightbulb /> Learn
                    </button>
                  )}
                  <button id='bookButton' className="book-button read-button" onClick={(e) => handleReadClick(e, book.title)}>
                    <Book /> Read
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="center-button">
            <p>Your reading list is empty.</p>
          </div>
        )}
    </div>

      {showDeleteModal && (
        <div className="search-modal-overlay">
          <div className="search-modal">
            <button className="close-button" onClick={handleDismissDelete}>
              &times;
            </button>
            <div className="search-result-content">
              <p>Are you sure you want to remove this book from your reading list?</p>
              <button id='standardButton' onClick={confirmDeleteBook}>
                Remove
              </button>
              <button id='standardButton' onClick={handleDismissDelete}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
      </div>
    </>
  );
};

export default ReadingListPage;


