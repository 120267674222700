import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './About';
import Privacy from './Privacy';
import Home from './Home';
import BookPage from './BookPage';
import AuthorPage from './AuthorPage';
import LearnPage from './LearnPage';
import ReadingListPage from './ReadingList';
import OpenAI from 'openai';
import SearchBar from './OpenAI';
import NotePage from './Notes';
import TestEpubPage from './TestEpubPage';
import FilmHome from './FilmList';
import FilmPage from './FilmPage';

function App() {


  return (
    <Router>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/film" element={<FilmHome />} />
        <Route path="/watch/:nameOfFilm" element={<FilmPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/test" element={<TestEpubPage />} />
        <Route path="/book/:title/:page?" element={<BookPage />} />
        <Route path="/readinglist" element={<ReadingListPage />} />
        <Route path="/:title/notes" element={<NotePage/>} />
        <Route path="/learn/book/:title/:page?" element={<LearnPage/>} />
        <Route path="/author/:authorName" element={<AuthorPage />} />
        <Route path="/ai" element={<SearchBar />} />
      </Routes>

    </Router>
  );
}

export default App;
